<template>
  <div id="home">
    <div class="head">
      <video id="v1" autoplay loop muted class="video">
        <source src="@/assets/video-bg.mp4" type="video/mp4" class="source" />
      </video>
      <div class="mask"></div>
      <div class="main" id="container">
        <div class="main-img">
          <img src="../../assets/images/tubiao.png" />
        </div>
        <h2>枫林云营销</h2>
        <p class="title1">房地产行业数字化营销引领者</p>
        <p class="transverse"></p>
        <p class="work">· 咨 询 · 调 研 · 开 发 · 集 成 · 部 署 · 运 维 · 运 营 ·</p>
        <a href="../experience" class="experience">试用体验</a>
        <ul class="flex banner-li">
          <li v-for="item in cardList" :key="item.img">
            <a :href="item.url">
              <img :src="item.img" class="card" />
            </a>
            <p style="margin: 0">{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="marketing-services" ref="numTotal">
      <h4>枫林云数字化营销服务</h4>
      <ul class="flex" id="container">
        <li class="flex" v-for="item in cardList1" :key="item.img">
          <img :src="item.img" />
          <div>
            <p class="number">
              <ICountUp :delay="delay" :endVal="item.defaultN" :options="options" />
              {{ item.symbol }}
            </p>
            <p class="text">{{ item.text }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="product" ref="product">
      <img src="../../assets/images/banner3.png" alt="" />
    </div>
    <div class="experience-now flex">
      <video id="v1" autoplay loop muted class="video">
        <source src="@/assets/video-bg3.mp4" type="video/mp4" class="source" />
      </video>
      <div class="button">
        <p>营销数字化升级，从现在开始！</p>
        <a href="../experience" class="btn btn-1 btn-1b">立即体验</a>
      </div>
    </div>
    <!-- <full-page :options="options1">
      <div class="section bg1">
            <div class="head">
      <video id="v1" autoplay loop muted class="video">
        <source src="@/assets/video-bg.mp4" type="video/mp4" class="source" />
      </video>
      <div class="mask"></div>
      <div class="main" id="container">
            <div class="main-img">
              <img src="../../assets/images/tubiao.png" />
            </div>          
            <h2>枫林云营销</h2>
            <p class="title1">房地产行业数字化营销引领者</p>
            <p class="transverse"></p>
            <p class="work">· 咨询 · 调研 · 开发 · 集成 · 部署 · 运维 · 运营 ·</p>
            <a href="../experience" class="experience">试用体验</a>
        <ul class="flex banner-li">
          <li v-for="item in cardList" :key="item.img">
            <a :href="item.url">
              <img :src="item.img" class="card" />
            </a>
            <p style="margin: 0">{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </div>
      </div>
      <div class="section bgtl">
          <div class="marketing-services" ref="numTotal">
      <h4>枫林云数字化营销服务</h4>
      <ul class="flex" id="container">
        <li class="flex" v-for="item in cardList1" :key="item.img">
          <img :src="item.img" />
          <div>
            <p class="number">
              <ICountUp :delay="delay" :endVal="item.defaultN" :options="options" />
              {{ item.symbol }}
            </p>
            <p class="text">{{ item.text }}</p>
          </div>
        </li>
      </ul>
    </div>
      </div>
      <div class="section bg2">
        <div class="product" ref="product">
      <img src="../../assets/images/banner3.png" alt="" />
    </div>
      </div>
      <div class="section bg3">
        <div>         
     <div class="experience-now flex">
      <video id="v1" autoplay loop muted class="video">
        <source src="@/assets/video-bg3.mp4" type="video/mp4" class="source" />
      </video>
      <div class="button">
        <p>营销数字化升级，从现在开始！</p>
        <a href="../experience" class="btn btn-1 btn-1b">立即体验</a>
      </div>
    </div>
         <Footer></Footer>
      </div>
        </div>
    </full-page> -->
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2'
import { isElementNotInViewport } from '@/utils'
import { BusEmit } from '@/utils/eventBus.js'
import rallax from 'rallax.js'
import Footer from '../../layout/components/footer.vue'
export default {
  data() {
    return {
      options1: {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        afterLoad: this.afterLoad,
        scrollOverflow: true, //内容超过满屏后显示滚动条
        scrollBar: true,
        menu: '#menu'
        // sectionsColor:[
        // ]
      },
      delay: 400,
      product: null,
      numTotal: null,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: '',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      cardList: [
        {
          img: require('../../assets/images/card1.png'),
          text: '线上售楼处',
          url: '../product'
        },
        {
          img: require('../../assets/images/card2.png'),
          text: '全民营销',
          url: '../product'
        },
        {
          img: require('../../assets/images/card3.png'),
          text: '中介分销',
          url: '../product'
        },
        {
          img: require('../../assets/images/card4.png'),
          text: '自拓行销',
          url: '../product'
        },
        {
          img: require('../../assets/images/card5.png'),
          text: '来访接待台',
          url: '../product'
        },
        {
          img: require('../../assets/images/card6.png'),
          text: '移动案场',
          url: '../product'
        },
        {
          img: require('../../assets/images/card7.png'),
          text: '业务中台',
          url: '../product'
        }
      ],
      cardList1: [
        {
          img: require('../../assets/images/icon切图1.gif'),
          number: 3000,
          defaultN: 0,
          symbol: '+',
          text: '产品覆盖项目'
        },
        {
          img: require('../../assets/images/icon切图2.gif'),
          number: 35,
          defaultN: 0,

          symbol: '%',
          text: '百强房企覆盖率'
        },
        {
          img: require('../../assets/images/icon切图3.gif'),
          number: 95,
          defaultN: 0,

          symbol: '%',
          text: '业务场景覆盖率'
        },
        {
          img: require('../../assets/images/icon切图4.gif'),
          number: 300000,
          defaultN: 0,

          symbol: '+',
          text: '单项目最高访问量'
        }
      ]
    }
  },
  components: {
    ICountUp
    // Footer
  },
  methods: {
    handScroll(c) {
      const el = this.numTotal
      const product = this.product
      const top = el.getBoundingClientRect() && el.getBoundingClientRect().top
      const productTop = product.getBoundingClientRect() && product.getBoundingClientRect().top

      const isViewport = isElementNotInViewport(el)
      console.log(top)
      console.log(productTop)
      if (isViewport) {
        this.cardList1.forEach((i, index) => {
          const defaultN = i.number
          this.$set(this.cardList1[index], 'defaultN', defaultN)
        })
      }
    }
  },
  mounted() {
    this.numTotal = this.$refs.numTotal
    this.product = this.$refs.product
    window.addEventListener('scroll', this.handScroll)
    // const parallax = rallax('.experience-now', { speed: 0.4 });
  }
}
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  li {
    list-style: none;
  }
  .video {
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    margin: auto;
    height: auto;
    width: auto;
    /*加滤镜*/
    /*filter: blur(15px); //背景模糊设置 */
    /*-webkit-filter: grayscale(100%);*/
    /*filter:grayscale(100%); //背景灰度设置*/
    z-index: -11;
  }
  .source {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
  }
  .mask {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  .main-img {
    width: 100%;
    position: absolute;
    right: 0;
    height: auto;
    width: auto;
    z-index: -9;
    img {
      width: 620px;
      z-index: 10;
    }
  }
  .head {
    width: 1920px;
    text-align: left;
    height: 940px;
    color: #fff;
    position: relative;
    margin: 0 auto;
    .main {
      box-sizing: border-box;
      padding-top: 175px;
      position: relative;
      z-index: 6;
      h2 {
        font-size: 100px;
        font-family: YouSheBiaoTiHei;
        color: #fff;
        margin-top: 0;
        line-height: 72px;
        margin-bottom: 47px;
        font-weight: 400;
      }
      .title1 {
        font-size: 34px;
        color: #fff;
        margin: 0;
      }
      .transverse {
        width: 162px;
        height: 4px;
        background: #ffffff;
        margin: 70px 0;
      }
      .work {
        font-size: 26px;
        font-weight: 100;
        color: #fefefe;
        margin: 0;
      }
      .experience {
        display: inline-block;
        width: 200px;
        height: 54px;
        margin: 66px 0;
        text-decoration: none;
        background-color: #fff;
        text-align: center;
        line-height: 54px;
        border-radius: 27px;
        color: #3451ff;
        font-size: 22px;
      }
      .experience:hover {
        background-color: $blue;
        color: #fff;
      }
      ul {
        justify-content: space-between;
        padding: 0;
        li {
          text-align: center;
          .card {
            margin-bottom: 20px;
            -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
            transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
          }
          .card:hover {
            box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
            transform: translate(0, -5px);
            transition-delay: 0s;
          }
        }
      }
    }
  }
  .marketing-services {
    width: 1920px;
    height: 950px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #fff;
    color: black;
    h4 {
      padding: 80px 0 100px;
      margin: 0;
      text-align: center;
      font-size: 36px;
      font-weight: 400;
    }
    ul {
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 50%;
        img {
          width: 140px;
          height: 140px;
          margin-right: 44px;
        }
        .number {
          font-size: 120px;
          font-weight: bold;
          margin-top: 0;
          line-height: 87px;
          margin-bottom: 30px;
          white-space: nowrap;
          font-family: 'DIN-Bold';
        }
        .text {
          font-size: 24px;
          text-align: left;
          margin: 30px 0;
        }
      }
      li:nth-of-type(1) {
        margin-bottom: 150px;
      }
      li:nth-of-type(2) {
        margin-bottom: 150px;
      }
    }
  }
  .product {
    height: 1000px;
    position: relative;
    width: 1920px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .experience-now {
    width: 1920px;
    height: 500px;
    position: relative;
    color: #fff;
    margin: 0 auto;
    .source {
      width: 100%;
      height: 100%;
    }
    .button {
      z-index: 2;
      text-align: center;
      margin: auto;
      p {
        font-weight: 100;
        font-size: 50px;
        margin: 0;
      }
    }
  }
}
.banner-li {
  li {
    a {
      display: inline-block;
      width: 90px;
      height: 90px;
      img {
        width: 90px;
        height: 90px;
      }
    }
  }
}
// 按钮样式
.btn {
  position: relative;
  display: inline-block;
  width: 330px;
  height: 60px;
  line-height: 60px;
  margin-top: 40px;
  color: $blue;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.3s;
  overflow: hidden;
  color: #fff;
  border: none;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.4s;
  }
}
.btn-1 {
  border: 3px solid #fff;
}
.btn-1:hover {
  border: 3px solid $blue;
  /* transform: scaleX(1.5); */
  /* background: red; */
}
.btn-1b:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: $blue;
}
.btn-1b:hover:after {
  height: 100%;
}
</style>
